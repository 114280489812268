import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
// components
import Events from "../Events/App";
import Footer from "../Footer/Footer";
import Gallery from "../Gallery/Gallery";
import News from "../News/News";
import Pages from "../Pages/Pages";
import ProfilePage from "../Profile/ProfilePage";
import SettingsPage from "../Settings/SettingsPage";
import ListItem from "./ListItem";
import User from "../User/User";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
// styles
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

import { useDispatch, useSelector } from "react-redux";
import { dashboardState } from "./reduxSlice";
import { useNavigate } from "react-router-dom";
import { loginoutUserAsync } from "../Login/reduxSlice";
import Video from "../Video/Video";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(10),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashboardTab } = useSelector(dashboardState);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            sx={{
              backgroundColor: "white",
              boxShadow: "0 2px 13px #0d6efd1f",
            }}
            open={open}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  pl: 2,
                  color: "#0c0c0c",
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon sx={{ color: "#000" }} />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="#000"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                LOGO
              </Typography>
              <IconButton color="inherit">
                <LogoutIcon
                  sx={{ color: "#000" }}
                  onClick={() => {
                    dispatch(loginoutUserAsync({ navigate }));
                  }}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <ListItem open={open} />
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ my: 4, height: "100%" }}>
              {(dashboardTab === "Profile" && <ProfilePage />) ||
                (dashboardTab === "Settings" && <SettingsPage />) ||
                (dashboardTab === "Pages" && <Pages />) ||
                (dashboardTab === "News & Events" && <News />) ||
                (dashboardTab === "Visitors" && <Events />) ||
                (dashboardTab === "Gallery" && <Gallery />) ||
                (dashboardTab === "Video Gallery" && <Video />) ||
                (dashboardTab === "User" && <User />)}
              <Footer />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createNews, getNews, deleteNews, updateNews } from "./reduxAPI";
const initialState = {
  loading: "",
  id: "",
  selectNews: "",
  deleteKey: "",
  editorUpload: "",
  Buttonloading: false,
  heading: "",
  date: "",
  content: "",
  files: [],
  website: "charity",
  newsCollection: [],
  formModel: false,
};

export const createNewsAsync = createAsyncThunk(
  "news/createNews",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { heading, date, content, files, website } = getState().news;
      let body = content;
      const response = await createNews(heading, date, body, files, website);
      dispatch(setFormModal({}));
      dispatch(getNewsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getNewsAsync = createAsyncThunk(
  "news/getNews",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken");
      const { website } = await getState().news;
      const response = getNews(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateNewsAsync = createAsyncThunk(
  "news/updateNews",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, deleteKey, heading, date, content, files, website } =
        getState().news;
      let body = content.toString();
      const response = await updateNews(
        id,
        deleteKey,
        heading,
        date,
        body,
        files,
        website
      );
      dispatch(setFormModal({}));
      dispatch(getNewsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteNewsAsync = createAsyncThunk(
  "news/deleteNews",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, deleteKey, website } = getState().news;
      const response = await deleteNews(id, deleteKey, website);
      dispatch(getNewsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.value;
    },
    setDeleteKey: (state, action) => {
      state.deleteKey = action.payload.value;
    },
    setEditorUpload: (state, action) => {
      state.editorUpload = action.payload.value;
    },
    setSelectedNew: (state, action) => {
      state.selectNews = action.payload.value;
    },
    setFormModal: (state, action) => {
      state.formModel = action.payload.value;
    },
    setHeading: (state, action) => {
      state.heading = action.payload.value;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setBody: (state, action) => {
      state.content = action.payload.value;
    },
    setImage: (state, action) => {
      state.files = [...state.files, action.payload.value];
    },
    clearState: (state) => {
      state.files = [];
      state.date = "";
      state.heading = "";
      state.content = "";
      state.selectNews = "";
      state.deleteKey = "";
      state.id = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(createNewsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(createNewsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(getNewsAsync.pending, (state, action) => {
        state.loading = true;
        state.newsCollection = [];
      })
      .addCase(getNewsAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((data) => {
          state.newsCollection = [...state.newsCollection, data].sort(function (
            a,
            b
          ) {
            return new Date(b.date) - new Date(a.date);
          });
        });
      })
      .addCase(getNewsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteNewsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(deleteNewsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(deleteNewsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(updateNewsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(updateNewsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(updateNewsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      });
  },
});

export const {
  setID,
  setDeleteKey,
  setSelectedNews,
  setFormModal,
  setHeading,
  setDate,
  setBody,
  setImage,
  clearState,
  setEditorUpload,
} = newsSlice.actions;
export const newsState = (state) => state.news;
export default newsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uploadImage, getGallery, deleteImage } from "./reduxAPI";
const initialState = {
  files: {
    file: "",
    name: "",
    type: "",
  },
  title: "",
  link: "",
  folder: "gallery",
  awsFolder: "gallery",
  collection: [],
  deleteToken: "",
  deleteKey: "",
  website: "charity",
  loading: false,
  buttonLoading: false,
  deleteLoading: false,
};

export const UploadFileAsync = createAsyncThunk(
  "gallery/uploadImage",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { files, title, link, folder, website } = getState().gallery;
      const response = await uploadImage(files, title, link, folder, website);
      dispatch(clearFields({}));
      dispatch(getGalleryAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getGalleryAsync = createAsyncThunk(
  "gallery/getGallery",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { awsFolder, website } = getState().gallery;
      const response = await getGallery(awsFolder, website);
      console.log(response);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteImageAsync = createAsyncThunk(
  "gallery/deleteImage",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { deleteKey, deleteToken, awsFolder, website } = getState().gallery;
      const response = await deleteImage({
        deleteKey,
        deleteToken,
        awsFolder,
        website,
      });
      dispatch(getGalleryAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setImage: (state, action) => {
      state.files.name = action.payload.name;
      state.files.type = action.payload.type;
      state.files.file = action.payload.file;
    },
    setFolder: (state, action) => {
      state.folder = action.payload.value;
    },
    setTitle: (state, action) => {
      state.title = action.payload.value;
    },
    setLink: (state, action) => {
      state.link = action.payload.value;
    },
    setAwsFolder: (state, action) => {
      state.awsFolder = action.payload.value;
    },
    setDeleteToken: (state, action) => {
      state.deleteToken = action.payload.value;
    },
    setDeleteKey: (state, action) => {
      state.deleteKey = action.payload.value;
    },
    clearFields: (state) => {
      state.files = {
        file: "",
        name: "",
        type: "",
      };
      state.folder = "";
      state.title = "";
      state.link = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UploadFileAsync.pending, (state, action) => {
        state.buttonLoading = true;
      })
      .addCase(UploadFileAsync.fulfilled, (state, action) => {
        state.buttonLoading = false;
      })
      .addCase(UploadFileAsync.rejected, (state, action) => {
        state.buttonLoading = false;
      })

      .addCase(getGalleryAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = [];
      })
      .addCase(getGalleryAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.collection = [...state.collection, item];
        });
      })
      .addCase(getGalleryAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteImageAsync.pending, (state, action) => {
        state.deleteLoading = true;
      })
      .addCase(deleteImageAsync.fulfilled, (state, action) => {
        state.deleteLoading = false;
      })
      .addCase(deleteImageAsync.rejected, (state, action) => {
        state.deleteLoading = false;
      });
  },
});

export const {
  setImage,
  setFolder,
  setTitle,
  setLink,
  setDeleteToken,
  setDeleteKey,
  setAwsFolder,
  clearFields,
} = gallerySlice.actions;
export const galleryState = (state) => state.gallery;
export default gallerySlice.reducer;

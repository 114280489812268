import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEvents,
  getEvents,
  deleteEvents,
  updateEvents,
} from "./reduxAPI";

const initialState = {
  title: "",
  content: "",
  date: "",
  gallery: [],
  loading: "",
  id: "",
  selectEvents: "",
  deleteKey: "",
  Buttonloading: false,
  website: "charity",
  eventsCollection: [],
  formModel: false,
  editorUpload:""
};

export const createEventsAsync = createAsyncThunk(
  "events/createEvents",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { title, date, content, gallery, website } = getState().events;
      let body = content.toString();
      const response = await createEvents(title, date, body, gallery, website);
      dispatch(setFormModal({}));
      dispatch(getEventsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getEventsAsync = createAsyncThunk(
  "events/getEvents",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken");
      const { website } = await getState().events;
      const response = getEvents(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateEventsAsync = createAsyncThunk(
  "events/updateEvents",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, deleteKey, title, date, content, gallery, website } =
        getState().events;
      let body = content.toString();
      const response = await updateEvents(
        id,
        deleteKey,
        title,
        date,
        body,
        gallery,
        website
      );
      dispatch(setFormModal({}));
      dispatch(getEventsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteEventsAsync = createAsyncThunk(
  "events/deleteEvents",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, deleteKey, website } = getState().events;
      const response = await deleteEvents(id, deleteKey, website);
      dispatch(getEventsAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.value;
    },
    setDeleteKey: (state, action) => {
      state.deleteKey = action.payload.value;
    },
    setSelectedNew: (state, action) => {
      state.selectEvents = action.payload.value;
    },
    setFormModal: (state, action) => {
      state.formModel = action.payload.value;
    },
    setEditorUpload:(state,action)=>{
      state.editorUpload = action.payload.value
    },
    setTitle: (state, action) => {
      state.title = action.payload.title;
    },
    setContent: (state, action) => {
      state.content = action.payload.content;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setImage: (state, action) => {
      state.gallery = [...state.gallery, action.payload.value];
    },
    clearState: (state) => {
      state.gallery = [];
      state.date = "";
      state.title = "";
      state.content = "";
      state.selectEvents = "";
      state.deleteKey = "";
      // state.id = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEventsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(createEventsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(createEventsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(getEventsAsync.pending, (state, action) => {
        state.loading = true;
        state.eventsCollection = [];
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((data) => {
          state.eventsCollection = [...state.eventsCollection, data].sort(
            function (a, b) {
              return new Date(b.date) - new Date(a.date);
            }
          );
        });
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteEventsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(deleteEventsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(deleteEventsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(updateEventsAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(updateEventsAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(updateEventsAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      });
  },
});

export const {
  setTitle,
  setContent,
  setDate,
  setImage,
  setID,
  setDeleteKey,
  setSelectedevents,
  setFormModal,
  clearState,
  setEditorUpload
} = eventSlice.actions;
export const eventState = (state) => state.events;
export default eventSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails, updateUserDetails } from "./reduxAPI";
const initialState = {
  loading: "",
  firstName: "",
  lastName: "",
  userName: "",
  website: "charity",
  userProfile: "",
  updateLoading: false
};

export const getUserDetailsAsync = createAsyncThunk(
  "profile/getUserDetails",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const UID = window.localStorage.getItem("sessionToken");
      const { website } = getState().profile;
      const response = await getUserDetails(UID, website);
      dispatch(setUserName({value: response?.data?.userName}))
      dispatch(setFirstName({value: response?.data?.firstName}))
      dispatch(setLastName({value: response?.data?.lastName}))
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateUserDetailsAsync = createAsyncThunk(
  "profile/updateUserDetails",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const UID = window.localStorage.getItem("sessionToken");
      const { userName, firstName, lastName, website } = getState().profile;
      const response = await updateUserDetails(
        UID,
        userName,
        firstName,
        lastName,
        website
      );
      dispatch(getUserDetailsAsync({}))
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload.value;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.value;
    },
    setUserName: (state, action) => {
      state.userName = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetailsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = action.payload.data;
      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateUserDetailsAsync.pending, (state, action) => {
        state.updateLoading = true;
      })
      .addCase(updateUserDetailsAsync.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateUserDetailsAsync.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});

export const { setFirstName,setLastName ,setUserName} = profileSlice.actions;
export const profileState = (state) => state.profile;
export default profileSlice.reducer;

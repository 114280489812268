import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../components/Components/Dashboard/reduxSlice";
import loginReducer from "../components/Components/Login/reduxSlice";
import newUserReducer from "../components/Components/User/reduxSlice";
import profileReducer from "../components/Components/Profile/reduxSlice";
import galleryReducer from "../components/Components/Gallery/reduxSlice";
import newsReducer from "../components/Components/News/reduxSlice";
import eventReducer from "../components/Components/Events/reduxSlice";
import videoGalleryReducer from "../components/Components/Video/reduxSlice";

const reducers = combineReducers({
  dashboard: dashboardReducer,
  login: loginReducer,
  newUser: newUserReducer,
  profile: profileReducer,
  gallery: galleryReducer,
  news: newsReducer,
  events: eventReducer,
  videoGallery: videoGalleryReducer,
});

export const store = configureStore({
  reducer: reducers,
});
